@import 'utils/includes.scss';

.Container {
    margin: auto;
    max-width: 720px;
    padding: 0px 16px;
}

.Container-fluid {
    max-width: none;
}

.Container-lg {
    max-width: 1200px;
}