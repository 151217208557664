@import 'utils/includes.scss';

.Header {
    @include smooth-transition();
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
}

.Header-line {
    border-bottom: 1px solid $primary-100;
}

.Header-logo {
    margin:auto;
    margin-top: 40px;
    margin-bottom: 8px;
    display: block;
    max-width: 300px;
    cursor: pointer;
    height: 100%;
}

.Header-main {
    overflow-x: scroll;
    white-space: nowrap;
}

.Header-item {
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
    cursor: pointer;
    text-align: left;
    background-color: $white;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 24px;
    color: $primary-200;
    &:hover {
        color: $primary-300;
        text-decoration: none;
    }
}

.Header-item-right {
    float: inherit;
    @include breakpoint(lg-and-up) {
        float: right;
    }
}
