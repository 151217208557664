@import 'utils/includes.scss';

.Color-white { color: $white; }
.Color-black { color: $black; }
.Color-primary-000 { color: $primary-000; }
.Color-primary-100 { color: $primary-100; }
.Color-primary-200 { color: $primary-200; }
.Color-primary-300 { color: $primary-300; }
.Color-primary-400 { color: $primary-400; }
.Color-primary-500 { color: $primary-500; }
.Color-secondary-100 { color: $secondary-100; }
.Color-secondary-200 { color: $secondary-200; }
.Color-secondary-300 { color: $secondary-300; }
.Color-secondary-400 { color: $secondary-400; }
.Color-secondary-500 { color: $secondary-500; }
.Color-success-100 { color: $success-100; }
.Color-success-200 { color: $success-200; }
.Color-success-300 { color: $success-300; }
.Color-success-400 { color: $success-400; }
.Color-success-500 { color: $success-500; }
.Color-alert-100 { color: $alert-100; }
.Color-alert-200 { color: $alert-200; }
.Color-alert-300 { color: $alert-300; }
.Color-alert-400 { color: $alert-400; }
.Color-alert-500 { color: $alert-500; }

.Color-bg-white { background-color: $white; }
.Color-bg-black { background-color: $black; }
.Color-bg-primary-000 { background-color: $primary-000; }
.Color-bg-primary-100 { background-color: $primary-100; }
.Color-bg-primary-200 { background-color: $primary-200; }
.Color-bg-primary-300 { background-color: $primary-300; }
.Color-bg-primary-400 { background-color: $primary-400; }
.Color-bg-primary-500 { background-color: $primary-500; }
.Color-bg-secondary-100 { background-color: $secondary-100; }
.Color-bg-secondary-200 { background-color: $secondary-200; }
.Color-bg-secondary-300 { background-color: $secondary-300; }
.Color-bg-secondary-400 { background-color: $secondary-400; }
.Color-bg-secondary-500 { background-color: $secondary-500; }
.Color-bg-success-100 { background-color: $success-100; }
.Color-bg-success-200 { background-color: $success-200; }
.Color-bg-success-300 { background-color: $success-300; }
.Color-bg-success-400 { background-color: $success-400; }
.Color-bg-success-500 { background-color: $success-500; }
.Color-bg-alert-100 { background-color: $alert-100; }
.Color-bg-alert-200 { background-color: $alert-200; }
.Color-bg-alert-300 { background-color: $alert-300; }
.Color-bg-alert-400 { background-color: $alert-400; }
.Color-bg-alert-500 { background-color: $alert-500; }
