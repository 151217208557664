@import 'utils/includes.scss';
@import 'utils/normalize.scss';

@font-face {
    font-family: 'EB Garamond';
    src: url('/fonts/EBGaramond-VariableFont_wght.ttf')
        format('truetype-variations');
    font-weight: 1 999;
}

@font-face {
    font-family: 'EB Garamond';
    src: url('/fonts/EBGaramond-Italic-VariableFont_wght.ttf')
        format('truetype-variations');
    font-weight: 1 999;
    font-style: italic;
}

body,
button,
input {
    font-family: 'EB Garamond';
    -webkit-font-smoothing: antialiased;
    line-height: 32px;
    font-size: 21px;
    color: $primary-300;
}

img {
    max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    -webkit-font-smoothing: antialiased;
    font-family: 'Helvetica Neue';
    line-height: 1.5em;
}

.stacked-sm {
    margin-bottom: 8px;
}
.stacked-md {
    margin-bottom: 16px;
}
.stacked-lg {
    margin-bottom: 32px;
}
.stacked-xl {
    margin-bottom: 64px;
}

.inline-sm {
    margin-right: 8px;
}
.inline-md {
    margin-right: 16px;
}
.inline-lg {
    margin-right: 32px;
}
.inline-xl {
    margin-right: 64px;
}

.inset-sm {
    padding: 8px;
}
.inset-md {
    padding: 16px;
}
.inset-lg {
    padding: 32px;
}
.inset-xl {
    padding: 64px;
}

a {
    text-decoration: none;
    color: $primary-300;
    &:hover {
        text-decoration: underline;
    }
}

blockquote {
    font-size: 32px;
}
